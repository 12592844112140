export const selectRequiredHtmlElement = (selector: string) => {
  const element = document.querySelector(selector);

  if (!element) {
    throw Error(
      `Unable to retrieve required HTMLElement from selector "${selector}"`,
    );
  }

  return element as HTMLElement;
};

export const requiredDatasetValue = (node: HTMLElement, attribute: string) => {
  const value = node.dataset[attribute];

  if (!value) {
    throw Error(`Unable to get value for "dataset.${attribute}"`);
  }

  return value;
};

export const optionalDatasetValue = (
  node: HTMLElement,
  attribute: string,
  fallbackValue: any = '',
): string => {
  return node.dataset[attribute] || fallbackValue;
};

export const nodeAttributeValue = (selector: string, attribute: string) => {
  const target = document.querySelector(selector);

  if (!target) {
    throw Error(`No element found with selector '${selector}'`);
  }

  return target.getAttribute(attribute) || undefined;
};

export const requiredNodeAttributeValue = (
  selector: string,
  attribute: string,
) => {
  const value = nodeAttributeValue(selector, attribute);
  if (!value) {
    throw Error(
      `Unable to get value for attribute "${attribute}" from selector "${selector}"`,
    );
  }

  return value;
};

export const parseBooleanString = (value: string) => {
  const stringValue = value.toLowerCase();
  return stringValue === 'true';
};
